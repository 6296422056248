.category {
    background-color: rgb(28, 28, 28);
    width: 100vw;
    height: 10vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.project_body {
    width: 100vw;
    height: 90vh;
}

.category-item {
    height: 9vh;
    width: 9vh;
    margin-left: 10px;
    margin-right: 10px;
    display: inline-block;
    transition: 0.3s;
    display: flex;
    justify-content: center;
}

@media (max-width: 800px) {
    .category {
        height: 8vh;
    }
    .category-item {
        scale: 0.8;
    }
    .project_sec {
        left: 5%;
        width: 90%;

        animation: appearMobile 0.5s;
    }

    .project_sec > .buttons {
        top: calc(40% + 9vw);
    }

    .project_sec > .descrip {
        top: calc(40% + 9vw);
    }
    
    .project_sec > .buttons > .btn {
        width: 45px;
        height: 45px;
        margin-left: 10px;
        margin-right: 10px;
    }

    .share_btn {
        top: 10px;
        right: 10px;
        width: 30px;
        height: 30px;
    }

    .project_sec > .title {
        font-size: calc(70% + 3vw);
    }

    .project_sec > .platform {
        font-size: calc(50% + 3vw);
    }

    .imgNow {
        scale: 1.2;
    }

    .imgAfter {
        scale: 1.2;
    }

    .imgBefore {
        scale: 1.2;
    }
}

@media (min-width: 800px) {
    .category {
        height: 10vh;
    }
    .category-item {
        scale: 1;
    }
    .project_sec {
        left: 20%;
        width: 60%;

        animation: appear 0.5s;
    }

    .project_sec > .buttons {
        top: calc(40% + 9vw);
    }

    .project_sec > .descrip {
        top: calc(40% + 9vw);
    }

    .project_sec > .buttons > .btn {
        width: 55px;
        height: 55px;
        margin-left: 20px;
        margin-right: 20px;
    }

    .share_btn {
        top: 20px;
        right: 20px;
        width: 40px;
        height: 40px;
    }

    .project_sec > .title {
        font-size: calc(70% + 1vw);
    }

    .project_sec > .platform {
        font-size: calc(50% + 1vw);
    }
}

.project_sec {
    background-color: rgb(15, 15, 15);
    position: absolute;
    top: 15vh;
    height: 80vh;
    text-align: left;
    transition: all 0.2s ease-in-out;
}

.project_sec > .buttons {
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
}

.project_sec > .buttons > .btn {
    background-color: #252525;
    border-radius: 8px;
}

.project_sec > .buttons > .btn:hover {
    width: 60px;
    height: 60px;
}

.project_sec > .buttons > .btn > img {
    width: 100%;
    height: 100%;
    transition: all 0.1s;
}

.project_sec > .title {
    color: white;
    margin-top: 15px;
    margin-left: 3%;
}

.share_btn {
    position:absolute;
}
.share_btn > img {
    width: 100%;
    height: 100%;
}

.project_sec > .platform {
    color: rgb(0, 240, 40);
    margin-top: calc(-18px - 0.5%);
    margin-left: 3%;
}

.project_sec > .descrip {
    color: white;
    position: relative;
    text-align: center;
    font-size: 18px;
    word-wrap: break-word;
}

.project_sec > .imgNow {
    cursor: pointer;
    position: absolute;
    width: 50%;
    left: 25%;
    top: 15%;
    border: 10px rgb(15, 15, 15) solid;
    transition: all 0.3s ease-in-out;
    z-index: 3;
}

.project_sec > .imgBefore {
    cursor: pointer;
    position: absolute;
    width: 45%;
    left: 10%;
    top: 17%;
    border: 5px rgb(15, 15, 15) solid;
    transition: all 0.3s ease-in-out;
    z-index: 2;
    filter: opacity(0.3) drop-shadow(0 0 0 #000000);
}

.project_sec > .imgBefore:hover {
    width: 50%;
    top: 15%;
    filter: opacity(0.3) drop-shadow(0 0 0 #ffffff);
}

.project_sec > .imgAfter {
    cursor: pointer;
    position: absolute;
    width: 45%;
    right: 10%;
    top: 17%;
    border: 5px rgb(15, 15, 15) solid;
    transition: all 0.3s ease-in-out;
    z-index: 1;
    filter: opacity(0.3) drop-shadow(0 0 0 #000000);
}

.project_sec > .imgAfter:hover {
    width: 50%;
    top: 15%;
    filter: opacity(0.3) drop-shadow(0 0 0 #ffffff);
}

.project_sec > .imgNow:hover {
    width: 60%;
    left: 20%;
    top: 10%;
    border: 5px white solid;
    filter: opacity(0.8) drop-shadow(0 0 0 #ffffff);
}

.category-item:hover {
    background-color: gray;
}

.category-item > img {
    height: 60%;
    margin-top: 20%;
}

.category-selected {
    background-color: white;
    border-radius: 10px;
    width: 7vh;
    height: 3px;
    position: fixed;
    top: 9vh;
}

.arrow {
    cursor: pointer;

    position: absolute;
    bottom: 10%;
    width: 50px;
    height: 50px;
    opacity: 1;

    animation: blink-effect 1s infinite;
}

.arrow:hover {
    scale: 1.1;
    filter: opacity(0.3) drop-shadow(0 0 0 #00ffd5);
}

#ar0 {
    left: calc(50% - 25px);
}

#ar1 {
    left: calc(50% - 50px);
}

#ar2 {
    left: calc(50%);
}

@keyframes blink-effect {
    from {
        bottom: 10%;
        opacity: 1;
    }
    50% {
        bottom: calc(10% - 20px);
        opacity: 0.5;
    }

    to {
        bottom: 10%;
        opacity: 1;
    }
}

@keyframes appear {
    from {
        left: 25%;
        width: 50%;
        opacity: 0;
    }

    to {
        width: 60%;
        opacity: 1;
    }
}

@keyframes appearMobile {
    from {
        left: 20%;
        width: 60%;
        opacity: 0;
    }

    to {
        width: 90%;
        opacity: 1;
    }
}