.listItem {
    width: 80vw;
    height: 100px;
    color: white;
    padding-left: 10px;

    background-color: rgb(20, 20, 20);
}

.listItem:hover {
    background-color: gray;
}

#list::-webkit-scrollbar {
    width: 6px;
}
#list::-webkit-scrollbar-thumb {
    background-color: #2f3542;
    border-radius: 10px;
}
#list::-webkit-scrollbar-track {
    background-color: grey;
    border-radius: 10px;
    box-shadow: inset 0px 0px 5px white;
}

#list {
    overflow-y: auto;
    height: 100vh;
}

#name {
    white-space: no-wrap;
    font-size: calc(4vh - 0.5vw);
    color: white;
}

#descrip {
    color: gray;
    font-size: calc(3vh - 0.5vw);
}

#listlink {
    text-decoration-line: blink;
}

#lang {
    font-size: calc(2.8vh - 0.3vw);
    margin-left: 10px;
    color: greenyellow;
}

#fork {
    font-size: calc(2.8vh - 0.3vw);
    margin-left: 6px;
    color: aqua;
}

#exit {
    position: absolute;
    right: 30px;
    bottom: 10px;
    width: 60px;
    height: 60px;
    border-radius: 10px;
    background-color: white;

    color: black;

    padding-left: 4px;
    padding-top: 4px;

    transition: 0.2s;
}

#exit:hover {
    background-color: gray;
}