#error {
    position: absolute;
    top: 20px;
    background-color: red;
    height: 300px;
    width: 100vw;
}

#error > h1 {
    position: absolute;
    color: white;
    left: 10px;
    top: -100px;
    font-size: 180px;
}

#error > span {
    color: gray;
    position: absolute;
    top: 200px;
    left: 40px;
    font-size: 30px;
}