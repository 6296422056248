@font-face {
  font-family: "DungGeun";
  font-weight: normal;
  src: url("./fonts/DungGeunMo.ttf") format("truetype");
}

html {

  -ms-content-zooming: none;
  -ms-touch-action: pan-x pan-y;
  
}

body {
  background-color: black;
  font-family: DungGeun;

  -webkit-user-select:none;
  -moz-user-select:none;
  -ms-user-select:none;
  user-select:none;
  overflow: hidden;
}

.draggable {
  -webkit-user-select:all;
  -moz-user-select:all;
  -ms-user-select:all;
  user-select:all
}

.pointer {
  cursor: pointer;
}

.hovertext {
  position: relative;
  border-bottom: 1px dotted black;
}

.hovertext:before {
  content: attr(data-hover);
  visibility: hidden;
  opacity: 0;
  width: max-content;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 5px 5px;
  transition: opacity 0.3s ease-in-out;

  position: absolute;
  z-index: 1;
  left: 0;
  top: 110%;
}

.hovertext:hover:before {
  opacity: 1;
  visibility: visible;
}